html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}
























$fontcolor: rgb(0, 50, 124);
$link-padding: 25px;


.header {
    h1 {
        float: left;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Callie Chalk Font', sans-serif;
        color: $fontcolor;
        font-weight: 1000;
        font-size: 30px;


    }

    height: 50px;
    padding: 20px;
    position: fixed;
    overflow: hidden;

}

.container {

    // display: flex;
    // align-items: flex start;
    // justify-content: center;

    display: flex;
    justify-content: center;
    align-items: center;
}

.centered {}

li {
    padding-top: 10px;
}

ul {
    // padding-top: 20px;
    list-style-type: '- ';
    font-family: 'Courier New', monospace;
    color: $fontcolor;
    font-weight: 700;
}

h1 {
    font-size: 42px;
    color: $fontcolor;
    font-family: 'Callie Chalk Font', sans-serif;
    text-align: center;
}

.lower-left {
    // float: left;
}

.upper {
    text-align: center;
    margin-top: 42px;
}

.lower {
    // margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

@media (min-width: 500px) {

    .lower {
        margin-top: 30px;
        display: flex;
        flex-direction: row;
    }

}


.lower-left {
    // float: right;
    margin-left: 60px;
    margin-top: 50px;

}

.lower-right {
    // float: right;
    margin-left: 60px;
    margin-top: 50px;
    


}